import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppGlobals } from 'src/app/app.globals';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  usuario: string = '';
  senha: string = '';

  constructor(private authService: AuthService, private router: Router) {}

  async ngOnInit(): Promise<void> {
    await this.authService.getAccessToken();
  
    const usuario = localStorage.getItem('authUsuario');
    const senha = localStorage.getItem('authSenha');
  
    AppGlobals.exibindoLogs("Token Usuario: " + usuario);
    AppGlobals.exibindoLogs("Token Senha: " + senha);
  }

}