import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { Contrato } from 'src/app/core/models/contrato';
import { ContratosService } from 'src/app/core/services/contratos.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule],
  selector: 'app-gestao-contratos',
  templateUrl: './gestao-contratos.component.html'
})
export class GestaoContratosComponent implements OnInit {
  contratos: Contrato[] = [];
  contratosFiltro: Contrato[] = [];
  nomeTouro: string = '';
  tipoSemen: string = '';
  apenasContratoAbertos: boolean = false;
  isLoading: boolean = false;
  listaLojas: string[] = [];
  lojaSelecionada: string = '';

  constructor(private tourosService: ContratosService) { }

  async ngOnInit() {
    this.isLoading = true;

    this.contratos = await this.tourosService.consultarContratos();
    this.contratosFiltro = this.contratos;

    this.listaLojas = [...new Set(this.contratos.map(gestao => gestao.loja))];

    this.isLoading = false;
  }

  filtrarContratos() {
    this.contratosFiltro = this.contratos.filter(x => x.nomeTouro.toUpperCase().includes(this.nomeTouro.toUpperCase()))

    if (this.tipoSemen != "")
      this.contratosFiltro = this.contratosFiltro.filter(x => x.tipoSemen.toUpperCase().includes(this.tipoSemen.toUpperCase()))

    if (this.apenasContratoAbertos)
      this.contratosFiltro = this.contratosFiltro.filter(x => {
        
        const dataFim = new Date(x.dataFim); 
        return dataFim >= new Date()
    
    });

    if (this.lojaSelecionada != "")   
      this.contratosFiltro = this.contratosFiltro.filter(x => x.loja == this.lojaSelecionada);


  }
}
