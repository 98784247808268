import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { AppComponent } from "src/app/app.component";
import { AppGlobals } from "src/app/app.globals";
import { Touro } from "src/app/core/models/touro";
import { TourosService } from "src/app/core/services/touros.service";

@Component({
  standalone: true,
  selector: 'app-touros',
  imports: [CommonModule, FormsModule, IonicModule],
  templateUrl: './meus-touros.component.html'
})
export class MeusTourosComponent implements OnInit {
  touros: Touro[] = [];
  tourosFiltro: Touro[] = [];
  nomeTouro: string = '';
  isLoading: boolean = false;
  apenasCentral: boolean = false;
  listaLojas: string[] = [];
  lojaSelecionada: string = '';

  constructor(private tourosService: TourosService) { }

  async ngOnInit() {
    this.isLoading = true;
    
    this.touros = await this.tourosService.consultarTouros();
    this.tourosFiltro = this.touros;

    this.listaLojas = [...new Set(this.touros.map(touro => touro.loja))];

    this.isLoading = false;
  }

  filtrarTouros() {
    this.tourosFiltro = this.touros.filter(x => x.nome.toUpperCase().includes(this.nomeTouro.toUpperCase()))

    if (this.apenasCentral)
      this.tourosFiltro = this.tourosFiltro.filter(x => x.apenasCentral);

    if (this.lojaSelecionada != "")   
      this.tourosFiltro = this.tourosFiltro.filter(x => x.loja == this.lojaSelecionada);
  }
}
