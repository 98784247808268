import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MonitorProducao } from "src/app/core/models/monitor-producao";
import { MonitorProducaoService } from "src/app/core/services/monitor-producao.service";

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule],
  selector: 'app-monitor-producao',
  templateUrl: './monitor-producao.component.html'
})
export class MonitorProducaoComponent implements OnInit {
  monitor: MonitorProducao[] = [];
  monitorFiltro: MonitorProducao[] = [];
  nomeTouro: string = '';
  tipoSemen: string = '';
  isLoading: boolean = false;
  dtaIni: Date = null;
  dtaFin: Date = null;
  listaLojas: string[] = [];
  lojaSelecionada: string = '';

  constructor(private monitorProducaoService: MonitorProducaoService) { }

  async ngOnInit() {
    this.isLoading = true;
    
    this.monitor = await this.monitorProducaoService.consultarMonitorProducao();
    this.monitorFiltro = this.monitor;

    this.listaLojas = [...new Set(this.monitor.map(monitor => monitor.loja))];

    this.isLoading = false;
  }

  filtrarProducao() {
    this.monitorFiltro = this.monitor.filter(x => x.nomeTouro.toUpperCase().includes(this.nomeTouro.toUpperCase()))

    this.monitorFiltro = this.monitor.filter(x => x.tipoSemen.toUpperCase().includes(this.tipoSemen.toUpperCase()))

    this.dtaIni = this.dtaIni ? this.dtaIni : null;
    this.dtaFin = this.dtaFin ? this.dtaFin : null;

    if (this.dtaIni != null && this.dtaFin != null){

      const dataInicial = new Date(this.dtaIni);
      const dataFinal = new Date(this.dtaFin);

      this.monitorFiltro = this.monitorFiltro.filter(x => {
        const dataColeta = new Date(x.dataColeta); // Certifique-se que x.dataColeta é uma string "yyyy-MM-dd"
        return dataColeta >= dataInicial && dataColeta <= dataFinal;
      });
    }

    if (this.lojaSelecionada != "")   
      this.monitorFiltro = this.monitorFiltro.filter(x => x.loja == this.lojaSelecionada);
    
  }
}
