import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Touro } from "../models/touro";
import { AppGlobals } from "src/app/app.globals";
import { AuthService } from "src/app/auth.service";

@Injectable({
  providedIn: 'root'
})
export class TourosService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  async getHeadersAPI(usuario: string): Promise<HttpHeaders> {
    await this.authService.getAccessToken(); 
  
    return new HttpHeaders({
      'code': usuario,
      //'code': "00028501",
      'tenantid': AppGlobals.TENANT_ID,
      'Authorization': AppGlobals.AUTH_TOKEN
    });
  }

  async consultarTouros(): Promise<Touro[]> {
    try {
      const usuario = localStorage.getItem('authUsuario');
      AppGlobals.exibindoLogs("Touro Service Usuario: " + usuario);

      const headers = await this.getHeadersAPI(usuario);

      const urlApi = AppGlobals.API_URL + "/rest/meustouros/v1/touro/:cliente/touros";
      AppGlobals.exibindoLogs("Touro Service URL Final: " + urlApi);

      const response = await this.http.get<{ code: number, message: string, dados: any[] }>(urlApi, { headers: headers }).toPromise();
      
      return response.dados.map(dado => ({
        loja: dado.loja,
        codigo: dado.touro,
        nome: dado.nome_de_guerra,
        dataEntrada: AppGlobals.stringToDate(dado.dt_entrada),
        pesoEntrada: dado.peso_entrada,
        inicioQuarentena: AppGlobals.stringToDate(dado.ini_quarentena),
        prevEntradaColeta: AppGlobals.stringToDate(dado.prev_entr_coleta),
        fimQuarentena: AppGlobals.stringToDate(dado.fim_quarentena),
        dataSaida: AppGlobals.stringToDate(dado.dt_saida),
        ultimaPesagem: dado.ultima_pesagem,
        dataUltimaPesagem: dado.data_ultima_pesagem,
        apenasCentral: (AppGlobals.stringToDate(dado.fim_quarentena) != null) && (AppGlobals.stringToDate(dado.dt_saida) == null)
      }));

    } catch (error) {
      console.error('Erro ao consultar touros:', error);
      // Tratar erro conforme necessário ou lançar para tratamento externo
      throw error;
    }
  }
}
