import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MovimentoEstoque } from 'src/app/core/models/movimento-estoque';
import { MovimentoEstoqueService } from 'src/app/core/services/movimento-estoque.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule],
  selector: 'app-movimento-estoque',
  templateUrl: './movimento-estoque.component.html'
})
export class MovimentoEstoqueComponent implements OnInit {
  movimento: MovimentoEstoque[] = [];
  movimentoFiltro: MovimentoEstoque[] = [];
  contrato: string = '';
  isLoading: boolean = false;
  dtaIni: Date = null;
  dtaFin: Date = null;
  listaTouros: string[] = [];
  touroSelecionado: string = '';
  listaLojas: string[] = [];
  lojaSelecionada: string = '';

  constructor(private movimentoEstoqueService: MovimentoEstoqueService) { }

  async ngOnInit() {
    this.isLoading = true;
    
    this.movimento = await this.movimentoEstoqueService.consultarMovimentoEstoque();
    this.movimentoFiltro = [];
    this.listaTouros = [...new Set(this.movimento.map(mov => mov.contrato))];
    this.listaLojas = [...new Set(this.movimento.map(mov => mov.loja))];

    this.isLoading = false;
  }

  filtrarMovimentos() {

    if (this.touroSelecionado != "")
      this.movimentoFiltro = this.movimento.filter(x => x.contrato.toUpperCase() == this.touroSelecionado.toUpperCase());

    //this.movimentoFiltro = this.movimento.filter(x => x.contrato.toUpperCase().includes(this.contrato.toUpperCase()))

    this.dtaIni = this.dtaIni ? this.dtaIni : null;
    this.dtaFin = this.dtaFin ? this.dtaFin : null;

    if (this.dtaIni != null && this.dtaFin != null){

      const dataInicial = new Date(this.dtaIni);
      const dataFinal = new Date(this.dtaFin);

      this.movimentoFiltro = this.movimentoFiltro.filter(x => {
        const dataFim = new Date(x.data); 
        return dataFim >= dataInicial && dataFim <= dataFinal;
      });
    }
  }
}
