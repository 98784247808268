import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MovimentoEstoque } from "../models/movimento-estoque";
import { AppGlobals } from "src/app/app.globals";
import { AuthService } from "src/app/auth.service";

@Injectable({
  providedIn: 'root'
})
export class MovimentoEstoqueService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  async getHeadersAPI(usuario: string): Promise<HttpHeaders> {
    await this.authService.getAccessToken(); 
  
    return new HttpHeaders({
      'code': usuario,
      //'code': "00028501",
      'tenantid': AppGlobals.TENANT_ID,
      'Authorization': AppGlobals.AUTH_TOKEN
    });
  }

  async consultarMovimentoEstoque(): Promise<MovimentoEstoque[]> {
    try {
      const usuario = localStorage.getItem('authUsuario');
      AppGlobals.exibindoLogs("Movimento de Estoque Service Usuario: " + usuario);

      const headers = await this.getHeadersAPI(usuario);

      const urlApi = AppGlobals.API_URL + "/rest/meustouros/v1/touro/:cliente/movimentosestoque";
      AppGlobals.exibindoLogs("Movimento de Estoque Service URL Final: " + urlApi);

      const response = await this.http.get<{ code: number, message: string, dados: any[] }>(urlApi, { headers: headers }).toPromise();
      
      return response.dados.map(dado => ({
        loja: dado.loja,
        contrato: dado.contrato,
        operacao: dado.operacao,
        documento: dado.documento,
        data: AppGlobals.stringToDate(dado.data),
        doses: dado.doses,
      }));

    } catch (error) {
      console.error('Erro ao consultar Movimento de Estoque:', error);
      // Tratar erro conforme necessário ou lançar para tratamento externo
      throw error;
    }
  }
}
