import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Contrato } from "../models/contrato";
import { AppGlobals } from "src/app/app.globals";
import { AuthService } from "src/app/auth.service";

@Injectable({
  providedIn: 'root'
})
export class ContratosService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  async getHeadersAPI(usuario: string): Promise<HttpHeaders> {
    await this.authService.getAccessToken(); 
  
    return new HttpHeaders({
      'code': usuario,
      //'code': "00028501",
      'tenantid': AppGlobals.TENANT_ID,
      'Authorization': AppGlobals.AUTH_TOKEN
    });
  }

  async consultarContratos(): Promise<Contrato[]> {
    try {
      const usuario = localStorage.getItem('authUsuario');
      AppGlobals.exibindoLogs("Contrato Service Usuario: " + usuario);

      const headers = await this.getHeadersAPI(usuario);

      const urlApi = AppGlobals.API_URL + "/rest/meustouros/v1/touro/:cliente/contratos";
      AppGlobals.exibindoLogs("Contrato Service URL Final: " + urlApi);

      const response = await this.http.get<{ code: number, message: string, dados: any[] }>(urlApi, { headers: headers }).toPromise();
      
      return response.dados.map(dado => ({
        loja: dado.loja,
        codigo: dado.contrato,
        codigoTouro: dado.touro,
        nomeTouro: dado.nome_guerra,
        tipoSemen: dado.tipo_semen,
        nomeTipoSemen: AppGlobals.fRetornaTipoSemen(dado.tipo_semen),
        destino: dado.destino,
        nomeDestino: AppGlobals.fRetornaNomeDestino(dado.destino),
        palheta: dado.palheta,
        nomePalheta: AppGlobals.fRetornaNomePalheta(dado.palheta),
        dataInicio: AppGlobals.stringToDate(dado.dt_inicio),
        dataFim: AppGlobals.stringToDate(dado.dt_fim),
        quantidadeContrato: dado.qtd_tot_contrato,
        quantidadeProduzida: dado.qtd_produzida,
        emAbertoQtd: (dado.qtd_tot_contrato - dado.qtd_produzida),
        emAbertoPerc: (((dado.qtd_tot_contrato - dado.qtd_produzida) / dado.qtd_tot_contrato) * 100),
      }));

    } catch (error) {
      console.error('Erro ao consultar de contratos:', error);
      // Tratar erro conforme necessário ou lançar para tratamento externo
      throw error;
    }
  }
}
