import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MonitorProducao } from "../models/monitor-producao";
import { AppGlobals } from "src/app/app.globals";
import { AuthService } from "src/app/auth.service";

@Injectable({
  providedIn: 'root'
})
export class MonitorProducaoService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  async getHeadersAPI(usuario: string): Promise<HttpHeaders> {
    await this.authService.getAccessToken(); 
  
    return new HttpHeaders({
      'code': usuario,
      //'code': "00028501",
      'tenantid': AppGlobals.TENANT_ID,
      'Authorization': AppGlobals.AUTH_TOKEN
    });
  }

  async consultarMonitorProducao(): Promise<MonitorProducao[]> {
    try {
      const usuario = localStorage.getItem('authUsuario');
      AppGlobals.exibindoLogs("Monitor Producao Service Usuario: " + usuario);

      const headers = await this.getHeadersAPI(usuario);

      const urlApi = AppGlobals.API_URL + "/rest/meustouros/v1/touro/:cliente/MonitorProducao";
      AppGlobals.exibindoLogs("Monitor Producao Service URL Final: " + urlApi);

      const response = await this.http.get<{ code: number, message: string, dados: any[] }>(urlApi, { headers: headers }).toPromise();
      AppGlobals.exibindoLogs("Monitor Producao Resposta");
      
      return response.dados.map(dado => ({
        loja: dado.loja,
        contrato: dado.contrato,
        codigoTouro: dado.touro,
        nomeTouro: dado.nome_guerra,
        tipoSemen: dado.tipo_semen,
        nomeTipoSemen: AppGlobals.fRetornaTipoSemen(dado.tipo_semen),
        raca: dado.raça,
        dataColeta: AppGlobals.stringToDate(dado.dt_coleta),
        partida: dado.partida,
        motil: dado.motil,
        vigor: dado.vigr,
        conc: dado.conc,
        totalDefMai: dado.total_def_mai,
        totalDefMen: dado.total_def_men,
        totalAnor: dado.total_anor,
        status: dado.status == "L" ? "Liberado" : "",
        dsProdEstoque: dado.ds_prod_esqt,
        dataEntradaEstoque: dado.dt_ent_estq,
      }));

    } catch (error) {
      console.error('Erro ao consultar de Monitor Producao:', error);
      // Tratar erro conforme necessário ou lançar para tratamento externo
      throw error;
    }
  }
}
