import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd  } from '@angular/router';
import { ResponsiveService } from './shared/responsive.service';
import { AppGlobals } from './app.globals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'CBV-ADMIN';

  constructor(private router: Router, private responsiveService: ResponsiveService) {}

  ngOnInit() {
    this.responsiveService.getScreenWidth().subscribe(width => {
      AppGlobals.PAGE_WID = width;
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const body = document.querySelector('body');
        if (body) {
          body.className = ''; // Limpa todas as classes existentes
          if (event.url === '/login') {
            body.classList.add('login-page');
          } else {
            body.classList.add('home-page');
          }
        }
      }
    });
  }

  showHeader(): boolean {
    const hiddenRoutes = ['/login'];
    return !hiddenRoutes.includes(this.router.url);
  }
}
