import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import { AppGlobals } from 'src/app/app.globals';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  usuario: string = '';
  senha: string = '';
  errorMessage: string | null = null;
  isLoading: boolean = false;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void { 
    localStorage.setItem('authUsuario', '');
    localStorage.setItem('authStore', '');
    localStorage.setItem('authSenha', '');
    this.authService.getAccessToken();
  }

  async onLogin(): Promise<void> {
    this.isLoading = true;
    //this.usuario = "000001";
    //this.senha = "XCT259X2";
    const codigoUsuario = this.usuario.substring(0, 6); 
    const lojaUsuario = this.usuario.substring(6);
    AppGlobals.STORE = lojaUsuario;    

    try {
      const response = await this.authService.login(codigoUsuario, this.senha);

      AppGlobals.exibindoLogs('Login bem-sucedido: ' + response);

      localStorage.setItem('authUsuario', codigoUsuario);
      localStorage.setItem('authStore', lojaUsuario);      
      localStorage.setItem('authSenha', this.senha);

      this.router.navigate(['/home']);

    } catch (error) {
      console.error('Erro de login:', JSON.stringify(error, null, 2));
      this.errorMessage = 'Usuário e/ou senha inválidos - ' + JSON.stringify(error, null, 2);

    } finally {
      this.isLoading = false; // Desativa o loading
    }
  }
}
