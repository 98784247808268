import { RouterModule, Routes } from "@angular/router";
import { GestaoContratosComponent } from "./gestao-contratos/gestao-contratos.component";
import { HomeComponent } from "./home/home.component";
import { MeusTourosComponent } from "./meus-touros/meus-touros.component";
import { MonitorProducaoComponent } from "./monitor-producao/monitor-producao.component";
import { MovimentoEstoqueComponent } from "./movimento-estoque/movimento-estoque.component";
import { NgModule } from '@angular/core';
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "../auth.guard";

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'meus-touros', component: MeusTourosComponent, canActivate: [AuthGuard] },
  { path: 'gestao-contratos', component: GestaoContratosComponent, canActivate: [AuthGuard] },
  { path: 'monitor-producao', component: MonitorProducaoComponent, canActivate: [AuthGuard] },
  { path: 'movimento-estoque', component: MovimentoEstoqueComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
