import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppGlobals } from './app.globals'; 

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) {}

  async getAccessToken(forcarAtualizar: boolean = true): Promise<void> {
    if (!AppGlobals.AUTH_TOKEN || forcarAtualizar) {
      const urlAdd = "/REST/api/oauth2/v1/token?grant_type=password&password=Secret@260424&username=wsetarp";
      const body = '';
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      });
  
      try {
        const response = await this.http.post<{ access_token: string }>(
          AppGlobals.API_URL + urlAdd,
          body,
          { headers }
        ).toPromise();
  
        AppGlobals.AUTH_TOKEN = "Bearer " + response.access_token;
        AppGlobals.exibindoLogs('Access Token atualizado: ' + AppGlobals.AUTH_TOKEN);
      } catch (error) {
        console.error('Erro ao obter o token', JSON.stringify(error, null, 2));
      }
    } else {
      AppGlobals.exibindoLogs('Token já disponível: ' + AppGlobals.AUTH_TOKEN);
    }
  }
  
  async getHeadersAPI(usuario: string, senha: string): Promise<HttpHeaders> {
    await this.getAccessToken(); 
  
    return new HttpHeaders({
      'code': usuario,
      'store': AppGlobals.STORE,
      'password': senha,
      'tenantid': AppGlobals.TENANT_ID,
      'Authorization': AppGlobals.AUTH_TOKEN 
    });
  }

  async login(usuario: string, senha: string): Promise<any> {
    
    const headers = await this.getHeadersAPI(usuario, senha);
  
    return this.http.post(AppGlobals.API_URL + "/rest/meustouros/v1/auth", '', { headers, responseType: 'text', observe: 'response' })
      .pipe(
        map(response => {
          if (response.status === 200) {
            return response.body; 
          } else {
            throw new Error(`HTTP status ${response.status}`); 
          }
        }),
        catchError(error => {
          console.error('Erro ao fazer login', JSON.stringify(error, null, 2));
          return throwError(error); 
        })
      ).toPromise(); 
  }

  // Função para tratar erros
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Ocorreu um erro inesperado!';
    if (error.error instanceof ErrorEvent) {
      
      errorMessage = `Erro: ${error.error.message}`;
    } else {
      
      errorMessage = `Código do erro: ${error.status}\nMensagem: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
