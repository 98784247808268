import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  private screenWidth = new BehaviorSubject<number>(window.innerWidth);  // Inicializa com o tamanho atual da tela

  constructor() {
    this.onResize();
  }

  // Método para monitorar o evento de redimensionamento
  onResize() {
    window.addEventListener('resize', () => {
      this.screenWidth.next(window.innerWidth);  // Atualiza a largura quando a janela é redimensionada
    });
  }

  // Método para pegar a largura atual da tela
  getScreenWidth() {
    return this.screenWidth.asObservable();  // Retorna um Observable que pode ser utilizado para obter o valor dinamicamente
  }
}
