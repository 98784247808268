import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { AppGlobals } from "src/app/app.globals";

@Component({
	standalone: true,
	imports: [CommonModule, FormsModule, RouterModule, IonicModule],
	selector: 'app-header',
	templateUrl: './header.component.html'
})
export class HeaderComponent {
	items = [
		{ name: 'Início', path: 'home' },
		{ name: 'Meus Touros na Central', path: 'meus-touros' },
		{ name: 'Gestão de Contratos', path: 'gestao-contratos' },
		{ name: 'Monitor de Produção', path: 'monitor-producao' },
		{ name: 'Movimentos de Estoque', path: 'movimento-estoque' },
		{ name: 'Sair', path: 'login' },
	];

	checkboxToggled: boolean = false;

	toggleDrawer() {
		this.checkboxToggled = !this.checkboxToggled
	}

	showImg(): boolean {

		if (AppGlobals.PAGE_WID < 700) {
			return false
		}
		else {
			return true;
		}
	}
}
