// src/app/globals/app.globals.ts
export class AppGlobals {
    public static readonly EXB_CONSOLE_LOG: boolean = true;
    public static readonly API_URL: string = 'http://200.178.138.135:48200';
    public static readonly TENANT_ID: string = '02,01';
    public static STORE: string = '';
    public static AUTH_TOKEN: string = '';
    public static PAGE_WID: number = 0;

    static exibindoLogs(log: string): void {
      if (AppGlobals.EXB_CONSOLE_LOG){
        console.log(log);
      }
    }

    static stringToDate(dateStr: string): Date | null {
      
      const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
      if (!datePattern.test(dateStr)) {
        return null;
      }
    
      const [day, month, year] = dateStr.split('/').map(part => parseInt(part, 10));
      const date = new Date(year, month - 1, day);
    
      if (
        date.getFullYear() !== year || 
        date.getMonth() !== month - 1 || 
        date.getDate() !== day
      ) {
        return null;
      }
    
      return date;
    }

    static fRetornaTipoSemen(input: string): string {
      switch (input) {
        case 'CV':
          return 'Convencional';
        case 'SM':
          return 'Sexado Macho';
        case 'SF':
          return 'Sexado Fêmea';
        default:
          return '';
      }
    }

    static fRetornaNomePalheta(input: string): string {
      switch (input) {
        case 'F':
          return 'Fina';
        case 'M':
          return 'Média';
        default:
          return '';
      }
    }

    static fRetornaNomeDestino(input: string): string {
      switch (input) {
        case '01':
          return 'Industrialização';
        case '06':
          return 'Outros';
        default:
          return '';
      }
    }
  }
  